import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    this.title.setTitle('Contact Prideplex - Get in Touch with Our Support Team');
    this.meta.updateTag({ name: 'description', content: `Have questions or need assistance? Contact Prideplex for support, inquiries, and more. We're here to help you enjoy the best Assamese entertainment.` });
    this.meta.updateTag({ name: 'keywords', content: `Prideplex Contact, Contact Prideplex, Prideplex Support, Contact Prideplex Support, Prideplex Help, Get in Touch Prideplex` });
  }

}
