import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Prideplex FAQ - Answers to Your Questions on Our Services');
    this.meta.updateTag({ name: 'description', content: 'Find answers to frequently asked questions about Prideplex. Learn about subscriptions, content, streaming, and more for a seamless viewing experience.' });
    this.meta.updateTag({ name: 'keywords', content: `Prideplex FAQ, Prideplex Questions, Prideplex Support, Prideplex Help` });
  }

}
