import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    this.title.setTitle('Terms and Conditions - Prideplex OTT Platform');
    this.meta.updateTag({ name: 'description', content: 'Read the Terms and Conditions of using Prideplex. Understand the guidelines, rules, and policies for a safe and enjoyable experience on our platform.' });
    this.meta.updateTag({ name: 'keywords', content: `Prideplex Terms and Conditions, Prideplex Terms of Service, Prideplex Legal, Prideplex User Agreement, Prideplex Policies, Prideplex Rules` });
  }

}
