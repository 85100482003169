import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    document.body.scrollTop = 0;
    this.title.setTitle('About Prideplex - Premium Assamese OTT Platform for All');
    this.meta.updateTag({ name: 'description', content: `Learn more about Prideplex, North-East India's first hybrid OTT platform. Discover our mission to provide exclusive Assamese content, movies, music, and more.` });
    this.meta.updateTag({ name: 'keywords', content: `Prideplex, Assamese OTT Platform, About Prideplex, North-East India OTT, Hybrid OTT Platform, Prideplex Mission` });
  }

}
