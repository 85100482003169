import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  constructor(private title: Title, private meta: Meta){}
    
    

  ngOnInit(): void {
    document.body.scrollTop = 0;
    this.title.setTitle('Privacy Policy - Prideplex | Your Data, Our Priority');
    this.meta.updateTag({name:'keywords',content:'Prideplex Privacy Policy Prideplex Data Privacy Prideplex Data Security'});    
    this.meta.updateTag({name:'description',content:`Prideplex Privacy Policy, Prideplex Data Privacy, Prideplex Data Security`});  
  }

}
